<template>
  <div>
    <headers />
    <div class="head">
      <img
        src="@/assets/images/achievementBj.png"
      />
      <div class="headTit">市场业绩</div>
      <div class="searchBox">
        <search @search="searchFun" :type="2" />
      </div>
    </div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资料获取</el-breadcrumb-item>
        <el-breadcrumb-item>市场业绩</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="leftBox" v-if="menuList.length>1">
        <div class="menuBox" v-for="(item, index) in menuList" :key="index">
          <div class="menuItem" @click="actMenu(index)">
            <div class="menuItemL" :style="act == index ? 'color:#00D8C3' : ''">
              {{ item.name }}
            </div>
            <div class="menuItemR"></div>
          </div>
        </div>
      </div>
      <div class="rightBox" :style="menuList.length>1?'':'width:100%'">
        <template v-if="list.length">
        <div class="card">
          <div
            :class="menuList.length>1?'cardItem':'cardItem ml45'"
            v-for="(item, index) in list"
            :key="index"
            @click="toAchievementDetails(item)"
          >
            <div class="imgBox">
              <img class="hoverTransform" :src="item.image" />
            </div>

            <div class="title textOverflowOne">
              {{ item.name }}
            </div>

            <div class="titleDetails textOverflowTwo">
              {{ item.performSummary }}
            </div>

            <div class="btnBox">
              <div>浏览：{{ item.readNum }}</div>
              <el-button class="searchBoxR">查看资料</el-button>
            </div>
          </div>
        </div>
        <div class="page">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pages.total"
            :page-size="pages.size"
             :current-page="pages.page"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
        </template>
        <None v-else />
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";

import { marketClassifyList, marketList, marketView } from "@/api/lx";

export default {
  name: "achievement",
  components: {
    headers,
    footers,
    search,
  },
  data() {
    return {
      act: 0,
      menuList: [],
      searchValue: "",
      pages: {
        page: 1,
        total: 0,
        size: 12,
      },
      list: [],
    };
  },
  created() {
    this.getMarketClassifyList();
  },
  mounted() {},
  methods: {
    currentChange(val) {
      this.pages.page = val;
      this.getMarketList();
    },
    searchFun(val) {
      this.searchValue = val;
      this.pages.page = 1;
      this.getMarketList();
    },
    getMarketClassifyList() {
      marketClassifyList({}).then((res) => {
        this.menuList = res.data;
        this.getMarketList();
      });
    },
    actMenu(val) {
      this.act = val;
      this.getMarketList();
    },
    getMarketList() {
      marketList({
        page: this.pages.page,
        size: this.pages.size,
        name: this.searchValue,
        classifyId: this.menuList[this.act].id,
      }).then((res) => {
        this.list = res.data.records;
        this.pages.total = res.data.total;
      });
    },
    toAchievementDetails(item) {
      this.$router.push({
        path: "/information/achievementDetails",
        query: { id: item.id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
  .headTit {
    width: 100%;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 42px;
    color: #ffffff;
    position: relative;
    z-index: 1;
    margin-top: 80px;
  }
  .searchBox {
    margin-top: 54px;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.content {
  width: 100%;
  display: flex;
  margin-top: 0px;
}
.leftBox {
  width: 477px;
  .menuBox {
    width: 393px;
    margin-left: 45px;
    padding: 15px 30px 15px 0px;
    box-sizing: border-box;
    border-top: 1px solid #e6edf0;
    cursor: pointer;
    .menuItem {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: 20px;
      color: #00161a;
    }
    .menuCellBox {
      border-top: 1px solid #e6edf0;
      padding-top: 15px;
      box-sizing: border-box;
      margin-top: 15px;
      :first-child {
        margin-top: 0 !important;
      }
      .menuCellItem {
        font-size: 16px;
        color: #00161a;
        line-height: 22px;
        margin-top: 15px;
      }
    }
  }
}
.rightBox {
  width: calc(100vw - 477px);
  margin-top: 15px;
}
.searchBox {
  margin-bottom: 20px;
}
.card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .cardItem {
    width: 330px;
    margin-right: 26px;
    margin-bottom: 26px;
    .imgBox {
      width: 330px;
      height: 186px;
      overflow: hidden;
      border: 1px dashed #e6edf0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-weight: 600;
      font-size: 18px;
      color: #00161a;
      margin-top: 15px;
    }
    .titleDetails {
      font-weight: 400;
      font-size: 14px;
      color: #525f66;
      text-align: left;
      margin-top: 10px;
      line-height: 20px;
      height: 40px;
    }
    .btnBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      div {
        font-weight: 400;
        font-size: 14px;
        color: #525f66;
      }
      .searchBoxR {
        width: 104px;
        height: 39px;
        background: #00d8c3;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
        &.is-active,
        &:active {
          border: 1px solid #00d8c3; /* 更改为你想要的颜色 */
          outline: none; /* 移除默认的轮廓线 */
        }
      }
    }
  }
}
.page {
  margin-left: 233px;
  margin-bottom: 55px;
}

.ml45{
  margin-left: 45px;
  margin-right: 0 !important;
}
</style>